// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useQuery } from '@tanstack/react-query';
import { ScaleTime } from 'd3';
import { DateTime } from 'luxon';

import { useResultDbClient } from '@/test_verdict/hooks/prpc_clients';

import {
  TIME_SPAN_HEIGHT,
  TIME_SPAN_TEXT_HEIGHT,
  TIME_SPAN_TEXT_OFFSET_X,
} from './constants';

export interface TimeSpanProps {
  readonly invName: string;
  readonly xScale: ScaleTime<number, number, never>;
}

export function TimeSpan({ invName, xScale }: TimeSpanProps) {
  const invId = invName.slice('invocations/'.length);
  const client = useResultDbClient();
  const {
    data: invocation,
    isLoading,
    isError,
    error,
  } = useQuery(client.GetInvocation.query({ name: invName }));
  if (isError) {
    throw error;
  }
  if (isLoading) {
    return <></>;
  }

  const createTime = DateTime.fromISO(invocation.createTime!);
  const finalizeTime = invocation.finalizeTime
    ? DateTime.fromISO(invocation.finalizeTime)
    : null;
  const start = xScale(createTime);
  const end = finalizeTime ? xScale(finalizeTime) : xScale.range()[1];

  return (
    <>
      <rect
        x={start}
        y={-TIME_SPAN_HEIGHT / 2}
        width={end - start}
        height={TIME_SPAN_HEIGHT}
        fill="var(--started-bg-color)"
        stroke="var(--started-color)"
      ></rect>
      <text
        textAnchor="start"
        x={start + TIME_SPAN_TEXT_OFFSET_X}
        y={TIME_SPAN_TEXT_HEIGHT / 2}
      >
        {invId}
      </text>
    </>
  );
}
